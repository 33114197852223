
import getHead from '~/utils/get-head';

export default {
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  head() {
    const { comparadiseToolUrl } = this.$config;

    const scripts = [
      {
        rel: 'preconnect',
        href: comparadiseToolUrl,
        crossorigin: true,
      },
      {
        body: true,
        type: 'text/javascript',
        hid: 'comparadise-snippet',
        innerHTML: `
              window.ComparadiseApplicationConfiguration = {
                autoMount: true,
                elementId: "comparadise",
                comparatorUrl :  "${this.config.comparatorUrl}"
              }
          `,
      },
      {
        body: true,
        type: 'text/javascript',
        async: true,
        src: `${comparadiseToolUrl}/iframe-wrapper.js`,
      },
    ];

    return {
      ...getHead(this, scripts),
    };
  },
};
